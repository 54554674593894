














































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { State, Action, Mutation, Getter } from 'vuex-class';
import { IUser } from '~/store/profile/types';
import { ICurrentPage, IPreviousPage } from '~/store/types';
import ProfileNavigationDrawer from '~/components/Home/ProfileNavigationDrawer.vue';
import ProfileProgressIcon from '~/components/Home/ProfileProgressIcon.vue';
import FullScreenDialog from '~/components/FullScreenDialog.vue';
import LoginAndRegister from '~/components/Authentication/LoginAndRegister.vue';
import NotificationBell from '~/components/SvgIcons/NotificationBell.vue';
import TriggerWalletCreationBtn from '~/components/NewWallet/TriggerWalletCreationBtn.vue';
import ReferAFriend from '~/components/Share/ReferralDialog.vue';
import PromoPackDialog from '~/components/Home/PromoPackDialog.vue';
import GalaTagLine from '../UIComponents/GalaTagLine.vue';
import DiscordIcon from '../UIComponents/DiscordIcon.vue';
import GalaLogo from '../UIComponents/GalaLogo.vue';
import MobileNavigationDrawer from './MobileNavigationDrawer.vue';
import SearchBar from '../UIComponents/SearchBar.vue';
import { kebabCase } from 'lodash';
import { ISearchItem } from '~/types/search-item';
import { GalaStoreToggleFilterCategory } from '../../types/gala-store';
import { IGame } from '../../store/games/types';

@Component({
  components: {
    ProfileNavigationDrawer,
    MobileNavigationDrawer,
    ProfileProgressIcon,
    FullScreenDialog,
    LoginAndRegister,
    NotificationBell,
    TriggerWalletCreationBtn,
    ReferAFriend,
    PromoPackDialog,
    GalaLogo,
    GalaTagLine,
    DiscordIcon,
    SearchBar,
  },
})
export default class TopToolbar extends Vue {
  @State('currentPage') currentPage!: ICurrentPage;
  @State('previousPage') previousPage!: IPreviousPage;
  @State(profile => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @State('discordLink') readonly discordLink!: string;
  @State(profile => profile.user.goldMember, { namespace: 'profile' })
  readonly goldMember!: boolean;

  @State(notifications => notifications.newNotifications.total, {
    namespace: 'notifications',
  })
  readonly newNotificationCount!: number;
  @State(games => games.games, { namespace: 'games' })
  games!: IGame[];

  @Mutation toggleReferralDialog!: (args: any) => void;

  @Action('userLogout', { namespace: 'profile' })
  private userLogout!: () => void;
  @Action('getNewNotifications', { namespace: 'notifications' })
  private getNewNotifications!: () => void;

  get tabs() {
    return [
      {
        title: 'Store',
        name: this.$t('layouts.default.store') as string,
        to: '/store',
        requirement: true,
      },
      {
        title: 'Games',
        name: this.$t('common.misc.games') as string,
        to: '/games',
        requirement: true,
      },
      {
        title: 'News',
        name: this.$t('common.misc.news') as string,
        to: 'https://news.gala.com/',
        target: '_blank',
        requirement: true,
      },
      {
        title: 'Discord',
        name: this.$t('common.navigation.discord'),
        to: this.discordLink,
        target: '_blank',
        requirement: true,
        mobile: true,
      },
      {
        title: 'Telegram',
        name: this.$t('common.navigation.telegram'),
        to: 'https://gogames.gala.com/ggtelegram',
        target: '_blank',
        requirement: true,
        mobile: true,
      },
    ].filter(tab => {
      return tab.requirement;
    });
  }

  drawerControl = false;
  mobileDrawerControl = false;
  clipped = true;
  showCongratsModal = false;
  nodeLink = process.env.nodeDashboardUrl;
  mobileSearch = false;
  telegramIcon = require('~/assets/icons/icon_telegram.svg');

  get accountControlsColor() {
    return this.goldMember
      ? 'accentGold'
      : this.darkMode
      ? 'white'
      : 'accentBlue';
  }

  get darkMode() {
    return this.$vuetify.theme.dark;
  }

  get themeChangeText() {
    return this.darkMode
      ? this.$t('components.home.topToolbar.lightsOn')
      : this.$t('components.home.topToolbar.lightsOff');
  }

  get discordConnected() {
    return !!this.currentUser.discordProfile;
  }

  handleThemeChange() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  async handleLogout() {
    this.drawerControl = false;
    await this.userLogout();
  }

  goBack() {
    if (
      (this.currentPage.forceBackRoute || this.previousPage.sameAsCurrent) &&
      this.currentPage.backRoute
    ) {
      this.$router.push(this.currentPage.backRoute);
    } else {
      this.$router.back();
    }
  }

  handleShowNotifications() {
    this.$router.push('/notifications');
  }

  onTrackNavigation(url: string) {
    this.$ua.trackNavigationEvent({
      to: url,
      from: this.$route.fullPath,
    });
  }

  @Watch('currentUser.loggedIn', { immediate: true })
  async onUserUpdated(loggedIn: Boolean) {
    if (loggedIn) {
      this.getNewNotifications();
    }
  }

  search(item: ISearchItem) {
    if (item.type === 'item') {
      this.$router.push(`/games/buy-item/${item.baseId}`);
    } else if (item.type === 'game') {
      const selectedGame = this.games.find(game => game.name === item.value);

      if (selectedGame?.showGamePage && selectedGame?.route) {
        // If a game with the searched name exists and has an associated route, it's preferable to use that route since
        // not all game routes are kebab-case versions of the current game name (for example, the route for Voyager: Ascension is just /voyager)
        this.$router.push({
          path: `/games/${selectedGame.route}`,
        });
      } else {
        // For some games like Town Star/Common Ground whose names have changed, the game name on the store item in the database may not match any
        // current game name, so finding the game and route using that game name won't work.
        // For those situations, this option will get the user to the correct page in most cases
        this.$router.push({
          path: `/games/${kebabCase(item.value.replace(/[^\s\w\d]/, ' '))}`,
        });
      }
    } else if (!!item.value) {
      this.$router.push({
        path: `/results`,
        query: {
          [GalaStoreToggleFilterCategory.name]: item.value,
          sortOption: 'relevance',
        },
      });
    }
  }
}
